
<div >
    <h3 style="padding:2%;">
      {{ 'Excel_Uploader.UPLOAD' | translate }}
    </h3>
    <div class="buttons-container">
      <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
      <input hidden (change)="onFileChange($event)" #fileInput type="file" id="file">
    </div>
  
    <div *ngIf="dataSource?.data">
      <table mat-table [dataSource]="dataSource" > <!-- matSort -->
        <ng-container>
  
        <!-- ID Column -->
        <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns">
          <th mat-header-cell *matHeaderCellDef > {{ 'Excel_Uploader.' + col | translate}} </th>
          <td mat-cell *matCellDef="let row;">
            <span>{{row[col]}}</span>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
  
      </ng-container>
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 100]" [length]="fileData.length"></mat-paginator>
    </div>
  </div>
  <footer>
    <div mat-dialog-actions style="position: relative; bottom: 0; right: 0; margin-top: 30px;">
      <button mat-button mat-dialog-close cdkFocusInitial [disabled]="!(file !== undefined)" (click)="updateData()">{{'Excel_Uploader.Upload' | translate}}</button>
      <button mat-button mat-dialog-close >{{'Excel_Uploader.Close' | translate}}</button>
    </div>
  </footer>
  