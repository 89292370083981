import { Component, ViewChild, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LibService } from 'src/app/services/lib.service';
import { MatPaginator } from '@angular/material/paginator';
import { UserService } from 'src/app/services/user.service';
import { cleanEmptyProperties } from 'src/app/utils/clean-empty-properties';

@Component({
  selector: 'app-excel-uploader-component',
  templateUrl: './excel-uploader-component.component.html',
  styleUrl: './excel-uploader-component.component.scss'
})
export class ExcelUploaderComponentComponent {

  fileData: any;
  file: any;

  displayedColumns!: string[];
  dataSource!: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private toastr: ToastrService,
              public translate: TranslateService,
              private userService: UserService,
              private libService: LibService,
              @Inject(MAT_DIALOG_DATA) public data: any)
  {

  }

  ngOnInit(): void {
    console.log("data --> ", this.data);
    console.log("nome del servizio -> ", this.data.service.name)
    this.displayedColumns = this.data.displayedColumns
  }

  onFileChange(e: any) {
    console.log("evento upload File -> ", e);
    let workBook:any = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial: any, name: any) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});

      this.fileData = jsonData[Object.keys(jsonData)[0]]
      this.dataSource = new MatTableDataSource(this.fileData)
      this.dataSource.paginator = this.paginator;
    }
    console.log("file -> ", file);
    this.file = file;
    file !== undefined?
      reader.readAsBinaryString(file):
      console.log("File Undefined")
  }

  updateData() {
    //MASSIVE UPLOAD
    this.libService.lockPage('Processing');
    this.fileData.forEach((current: any, index: any) => {
    let checkField = this.getCheckDuplicateField()
      
      setTimeout(() => {
        try {
          this.data.service.updateOneForImport(0, cleanEmptyProperties(current), checkField).subscribe((res: any) => {
            console.log("RES -> ", res)
            if (index + 1 === this.fileData.length){
              this.toastr.success(
                this.translate.instant('LABEL.OK'), 
                this.translate.instant('LABEL.Info')
              );
              // window.location.reload();
              this.libService.unlockPage();
            }
          })
        } catch (err) {
            console.error("ERROR --> ", err)
        }
      }, index * 1000);
    });
    
  }

  getCheckDuplicateField() {
    //MASSIVE UPLOAD
    switch (this.data.service.name) {
      case 'UserService':
        return 'email';
        break;
      default:
        return 'code';
        break;
    }
  }
}
