import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FrameComponent } from './frame.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SessionQuery } from 'src/app/session/login/session-query';
import {MatTreeModule} from '@angular/material/tree'
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [
    FrameComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatTableModule,
    MatButtonModule,
    MatTooltipModule,
    HttpClientModule,
    MatTreeModule,
    MatMenuModule,
    TranslateModule.forChild(),
    RouterModule.forChild([{ path: '', component: FrameComponent }])
  ],
  exports:[FrameComponent],
  providers:[SessionQuery]
})
export class FrameModule { }
