import { FindManyOptions, FindOneOptions } from "typeorm"
import { LibService } from "../services/lib.service";
import { switchMap, map, of } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

export abstract class BaseService<T>{

  abstract baseApiUrl : string

  constructor(public libService: LibService,
    protected http: HttpClient
  ) { }

  getAllExamples() {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${localStorage.getItem('access_token')}`);
    return this.http.get(this.baseApiUrl, { headers });
  }

  getOneById(id: string){
    return this.libService.getData<T>(this.baseApiUrl, '/' + id);
  }

  getAll(){
    return this.libService.getData<T[]>(this.baseApiUrl,'')
  }

  findOne(options?: FindOneOptions){
    return this.libService.postData<T>(options, this.baseApiUrl, '/findOne')
  }

  findMany(options: FindManyOptions){
    return this.libService.postData<T[]>(options, this.baseApiUrl, '/findMany')
  }

  createOne(item: T){
    return this.libService.postData<T>(item, this.baseApiUrl,'');
  }

  updateOne(id: string, item : T){
    return this.libService.putData<T>(item, this.baseApiUrl, '/' + id);
  }

  updateOneForImport(id: string, item : T, checkField: string) {
    //MASSIVE UPLOAD
    let options: FindOneOptions | any = { where: {}};
    options.where[checkField] = (item as any)[checkField]
    console.log("OPTIONS --> ", options)
    return this.findOne(options).pipe(
      switchMap(
        res => {
          console.log("RES DUPLICATE --> ", res);

          return  res !== null? 
                    of(null):
                    this.libService.putData<T>(item, this.baseApiUrl, '/' + id); 
        }
      )
    );
    
  }

  deleteOne(id : string){
    return this.libService.deleteData<T>(null, this.baseApiUrl,'/'+ id);
  }

  deleteCascade(item: T){
    return this.libService.deleteData(item, this.baseApiUrl, '/cascade')
  }
  getByFTSearch(keyword: any) {
    console.log("getRequestIdsFTSearch - KEYWORD SERVICE FRONT --> ", keyword)
    return this.libService.postData<any>(keyword, this.baseApiUrl, '/full-text-search');
  }

}
