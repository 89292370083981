import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { EmailTemplateListComponent } from './email-template-list/email-template-list.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { EmailRoutingModule } from './email-routing.module';
import { SessionQuery } from '../session/login/session-query';
import { OutgoingEmailService } from '../services/outgoing-email.service';
import { OutgoingEmailResolverService } from './outgoing-email-resolver.service';

export const MATERIAL_MODULES = [
  MatListModule,
  DragDropModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatIconModule,
  MatCheckboxModule,
  MatCardModule,
  MatSidenavModule,
  MatDialogModule,
  MatTableModule,
  MatCheckboxModule,
  MatExpansionModule
]

@NgModule({
  declarations: [
    EmailTemplateListComponent,
    EmailTemplateComponent
  ],
  imports: [
    MATERIAL_MODULES,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    EmailRoutingModule,
    NgxSummernoteModule,

  ],
  providers:[SessionQuery, OutgoingEmailService, OutgoingEmailResolverService]
})
export class EmailModule { }
