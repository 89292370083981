import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { TranslateService } from "@ngx-translate/core";
import { emit } from "process";
import { Observable, Subject, Subscriber, Subscription, debounceTime, distinctUntilChanged, forkJoin, map, mergeMap, of, switchMap, tap } from "rxjs";
import { AuthService } from "src/app/auth/auth.service";
import { User } from "src/app/models/user";
import { UserSessionService } from "src/app/services/user-session.service";
import { Any } from "typeorm";

@Component({
  selector: 'app-full-text-search',
  templateUrl: './full-text-search.component.html',
  styleUrls: ['./full-text-search.component.scss']
})
export class FullTextSearchComponent {

  @Input() service: any;
  @Input() component: any;
  @Input() searchType?: any;
  @Input() events?: Observable<void>;
  @Input() edit?: boolean;
  @Input() filterIDS: any;
  @Input() disableSearch!: boolean;
  @Input() searchCleaner?: boolean;
  type?: string;
  @Output() selected = new EventEmitter;
  @Output() clearOut = new EventEmitter;
  @Output() list = new EventEmitter;
  @Output() keyWord = new EventEmitter;


  noResults: boolean = false;
  fullTextSearchSubscriber?: Subscriber<any>;
  myControl: FormControl = new FormControl('');
  kwSearch: any;
  isLoading: boolean = false;
  options: any;
  richiesteDB: any[] = [];
  minCharOrder = 5;
  minChar = 2;
  loggedUser = this.authService.getUser();
  private eventsSubscription?: Subscription;

  optionList?: Array<any>;
  input = new Subject<string>();

  selectedSub: any;
  clearFields: boolean = false;
  inputValue$ = this.input.pipe(debounceTime(500), distinctUntilChanged());
  searchIds$ = this.inputValue$.pipe(
    switchMap((val) => {
      let words: any;
      val.includes(" ") ? words = val.split(" ") : undefined;
      this.isLoading = true;

      if (words !== undefined) {
        val = "";
        words = words.filter((a: any) => a.length > 2 || this.isNumeric(a) == true);
        words = words.map((a: any) => a.trim());
        console.log("WORDS/ PAROLE USATE PER RICERCA --> ", words);
        val = words.join(' ')
        console.log("PAROLA DA MANDARE AL DB --> ", val)

      }

      if (val.length > (this.service.name === 'OrderService' ? this.minCharOrder : this.minChar)) {
        return (this.service.name === 'OrderService' ? this.service.getByFTSearch({ kwsearch: val.toLowerCase(), username: this.loggedUser.email! }, this.filterIDS) as Observable<any> :
          this.service.getByFTSearch({ kwsearch: val.toLowerCase(), username: this.loggedUser.email! }) as Observable<any>)
      } else {
        return this.optionList = []
      }
    }),
    tap((subs) => {
      console.log("Soggetti --> ", subs, subs.length)
      subs.length == 0 ? this.optionList = [] : this.isLoading = true;
      this.type = subs.type
    }),
    map(subs => subs.data)
  );

  subjects$ = this.searchIds$

  // subjects$ = this.searchIds$.pipe(
  //   mergeMap(subs => {
  //     let ret: any;
  //     this.richiesteDB = [];
  //     if (subs.length > 0) {
  //       forkJoin(subs.map((sub: any) => {
  //         this.richiesteDB.push(sub[this.field]);
  //       }))
  //       ret = this.service.getById(this.richiesteDB)
  //     }
  //     else {
  //       ret = of([]);
  //     }
  //     return ret;
  //   }))

  indexAll$ = this.searchIds$.pipe(
    mergeMap(subs => {
      return subs.length > 0 ?
        forkJoin([[subs]].map((sub: any) => {
          let input = sub.flat(2);
          console.log('INPUT DA SERVICE', input)
          console.log("VETTORE FLATTATO --> ", sub.flat(2));
          return this.service.getById(input);
        }))
        :
        of([]);
    }),
    tap(subs => {
      this.type = 'all'
    }))

  constructor(public translate: TranslateService,
    private userSessionService: UserSessionService,
    private datePipe: DatePipe,
    private authService: AuthService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.searchCleaner === true ? this.clear() : undefined;

  }

  isNumeric(str: any): boolean {
    let control = true;
    let numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    Array.from(str).forEach((element: any) => {
      console.log("LETTERA --> ", element);
      numbers.includes(element) ? undefined : control = false;
    })

    return control;
  }

  ngOnInit(): void {

    // console.log("EVENTS --> ", this.events);
    if (this.events !== undefined && this.events !== null) {
      this.eventsSubscription = this.events.subscribe(() => {
        console.log("change search type -->");
        this.kwSearch = "";
      });
    }

    // this.searchType? this.kwSearch = '': undefined;
    if (this.service.name == "FTIndexAllService") {

      this.indexAll$.subscribe((res: any) => {
        console.log('index all mio: ', res.flat());
        this.isLoading = false;
        this.noResults = res.length > 0 ? false : true;
        this.optionList = res.flat();
        this.list.emit(this.optionList);
      });
    } else {

      this.subjects$.subscribe((res: any) => {
        // this.unSub();
        console.log("SERVIZIO DI RICERCA --> ", this.service.name);
        console.log('subjects mio: ', res);

        if (this.service.name === 'OrderService') {

          this.optionList = res[0];
          // this.filterList();
        } else {
          this.optionList = res.filter((op: any) => op !== null);
        }
        if (this.service.name === 'CardService') {
          this.noResults = this.optionList!.length > 0 ? false : true;
          this.isLoading = false;
          const movementsArray = this.optionList!
            .map((option: { movements: any; }) => option.movements)
            .filter((movements: null | undefined) => movements !== undefined && movements !== null);
          return this.list.emit(movementsArray);
        }
        this.noResults = this.optionList!.length > 0 ? false : true;
        this.isLoading = false;
        console.log("optionList", this.optionList)
        this.list.emit(this.optionList?.flat());
      });
    }
    this.isLoading = false;
  }

  fulltextSearch(event: any) {
    // this.unSub();
    let keyWord = event?.toString()
    console.log("searchType -> ", this.searchType)
    event = keyWord?.replace(/\s+/g, " ");
    this.keyWord.emit(event);
    if (!event) {
      this.isLoading = false
      console.log("ricerca VUOTA");
      this.optionList = [];
      this.list.emit(this.optionList);
    } else {
      event.length > 2 ?
        this.input.next(event) :
        undefined;
    }
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    console.log("TIPO DI SELEZIONE --> ", typeof event.option.value)
    if (typeof event.option.value == 'undefined') {
      console.log("DO NOTHING --> ",)
    } else {
      this.selectedSub = event.option.value;

      console.log("SOGGETTO SELEZIONATO --> ", this.selectedSub);
      console.log(this.service.name)
      switch (this.service.name) {
        case 'CardService':
          this.myControl.setValue(this.selectedSub.code);
          console.log("fulltext", this.selectedSub)
          this.selected.emit({ value: this.selectedSub, field: 'id' });
          break;
        case 'CustomerService':
          this.myControl.setValue(this.selectedSub.email);
          this.selected.emit({ value: this.selectedSub, field: 'id' });
          break;
        case 'MovementService':
          this.myControl.setValue(this.translate.get("MovementReason.Code.", this.selectedSub.code));
          this.list.emit(this.selectedSub);
          break;
          case 'UserService':
          this.myControl.setValue(this.selectedSub.email);
          this.selected.emit(this.selectedSub);
          break;
          case 'PermanancyService':
          this.myControl.setValue(this.selectedSub.code);
          this.selected.emit(this.selectedSub);
          break;
          case 'MovementReasonService':
            this.myControl.setValue(this.translate.get("MovementReason.Code.", this.selectedSub.code));
            this.list.emit(this.selectedSub);
            break;
        case 'subject':
          this.myControl.setValue(
            (this.selectedSub?.name !== null && this.selectedSub?.name !== undefined ?
              this.selectedSub?.name?.trim() :
              this.selectedSub?.legalEntity?.trim()) +
            ' ' +
            (this.selectedSub?.surname !== null && this.selectedSub?.surname !== undefined ?
              this.selectedSub?.surname?.trim() :
              ''));
          this.selected.emit({ value: this.selectedSub, field: 'code' });
          break;
        case 'orders':
          this.myControl.setValue(this.selectedSub?.code);
          this.selected.emit({ value: this.selectedSub, field: 'code' });

          break;
      }

    }
  }

  clear() {
    this.clearFields = true;
    this.kwSearch = "";
    this.clearOut.emit(this.clearFields);
    this.clearFields = false;
  }

  unSub() {
    console.log("SUBSCRIPTION --> ", this.eventsSubscription);
    this.eventsSubscription?.unsubscribe();
  }

  filterList() {
    console.log("LISTA PRE FILTRO --> ", this.optionList);
    if (this.filterIDS?.buildingId !== null && this.filterIDS?.buildingId !== undefined && this.filterIDS?.buildingId !== 0) {
      this.optionList = this.optionList?.filter(option => option.idBuilding === this.filterIDS.buildingId);
    }
    if (this.filterIDS?.subjectId !== null && this.filterIDS?.subjectId !== undefined && this.filterIDS?.subjectId !== 0) {
      this.optionList = this.optionList?.filter(option => option.idSubject === this.filterIDS.subjectId);
    }
  }

}
