import { Component, HostListener, OnInit, SimpleChanges,OnDestroy } from '@angular/core';
import { MatIconSVGService } from './services/mat-icon-svg.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest, debounceTime, distinctUntilChanged, fromEvent, of, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'netfidelity_frontend';
  userActivity?: any;
  userInactive: Subject<any> = new Subject();
  inactiveUser: boolean = false;
  prevEvent: any;
  mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(debounceTime(3000),distinctUntilChanged());
  url?: string;
  isTapped = false;


  constructor(
    private matIconService: MatIconSVGService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router
  )
  {
    // this.router.events.subscribe({
    //   next: event => {
    //     if(event instanceof NavigationEnd) {
    //       // event is an instance of NavigationEnd, get url!
    //       this.url = event.urlAfterRedirects;

    //       console.log("URL -> ", this.url);
    //       if (this.url.includes('login')) {
    //       } else {
    //         // this.setTimeout();
    //         this.userInactive
    //             .subscribe(() => {
    //           let onHidden$ = this.toastr.warning(
    //                             this.translate.instant('Toastr.logout_for_inactivity'),
    //                             this.translate.instant('Toastr.warning')
    //                           ).onHidden.subscribe({
    //                             next: (res) => {
    //                               this.isTapped === false?
    //                                 this.inactiveUser = true:
    //                                 this.inactiveUser = false;
    //                               this.isTapped = false;
    //                             }
    //                           }); ;
    //           let onTap$    = this.toastr.warning(
    //                             this.translate.instant('Toastr.logout_for_inactivity'),
    //                             this.translate.instant('Toastr.warning')
    //                           ).onTap.subscribe({
    //                             next: (res) => {
    //                               this.isTapped = true;
    //                               this.inactiveUser = false;
    //                             }
    //                           });
    //         });
    //       }
    //     }
    //   }
    // })
  }

  sendEvent() {
    this.userActivity = setTimeout(() => {
      let obs = this.userInactive.next(undefined)
      return obs
    }, 1000);
  }

  ngOnInit() {

    this.mouseMove$.subscribe(res => {
      //console.log("MOUSE MOVING");
      if (this.userInactive.closed === false){
        this.userActivity = null;
      }
      this.url?.includes('login')?
        console.log():
        this.sendEvent();
    })
  }

  ngOnDestroy(){
    sessionStorage.clear()
    localStorage.clear()
  }

  // @HostListener('mousemove', ['$event'])
  // handleMousemove(event: any) {

  // }



}
