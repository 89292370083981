import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/auth.service';
import { SessionService } from './session.service';
import { Credentials } from 'src/app/models/user';
import { SessionQuery } from './session-query';
import { LibService } from 'src/app/services/lib.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host:{
    class :'center-content'
  }
})
export class LoginComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isCustomer? : boolean

  showPassword: boolean = false;
  languages = this.translate.getLangs().filter(lang => lang === 'it' || lang === 'en');

  private sub!: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService,
    private userService: UserService,
    private authService: AuthService,
    private sessionService: SessionService,
    private session : SessionQuery,
    private libService : LibService

  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required /* Validators.pattern(/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/) */]],
    });
  }

  ngOnInit() {
    this.form.get('email')?.valueChanges.subscribe(email => {
      if (email === 'sys' ) {
        this.form.get('email')?.clearValidators();
        // this.form.get('password')?.clearValidators();
        this.form.setErrors(null);
        this.form.get('email')?.updateValueAndValidity({emitEvent: false});
        this.form.get('password')?.updateValueAndValidity({emitEvent: false});
        // this.form.clearValidators();
        // this.form.updateValueAndValidity()
      } else {
        this.form.get('email')?.setValidators([Validators.required, Validators.email]);
        this.form.get('password')?.setValidators([Validators.required]);
        // this.form.setErrors(null);
        this.form.get('email')?.updateValueAndValidity({emitEvent: false});
        this.form.get('password')?.updateValueAndValidity({emitEvent: false});
      }
    })
  }

  ngOnDestroy() {
    if (this.sub) { this.sub.unsubscribe(); }
  }

  login() {
    this.manageException()
    console.log("form -> ", this.form.getRawValue());
    if (this.form.valid) {

      const credentials: Credentials = {
        email: this.form.get('email')?.value,
        password: this.form.get('password')?.value,
      }
      this.sessionService.login((credentials)).subscribe(
        (res : any) => {
          console.log("CREDENZIALI",res);
          this.goToHome()
        },
        (err) => {
          console.error(err);
          this.toastr.error(this.translate.instant('HTTP.Errors.' + err.error.message));
        }
      );
  }
  }
  changePassword() {
    this.libService.lockPage('Sending email ...');
    this.sessionService.changePassword(this.form.getRawValue()).subscribe({
      next: (res) => {
        //console.log("res cambio pw --> ", res);
        this.libService.unlockPage()
        this.toastr.info(
          this.translate.instant('Toastr.email_sended')
        )
      },
      error: (err) => {
       // console.log("ERROR -> ", err)
        this.libService.unlockPage()
        this.toastr.error(
          this.translate.instant('Toastr.error_message'),
          this.translate.instant('Toastr.error'),
        )
      }
    })
  }

  handleError(error: any) {
    this.toastr.error(error);
  }

 /*  resetPassword() {
    this.userService.sendResetPasswordRequest({ userEmail: this.form.get('email')?.value }).subscribe(res => {
      this.toastr.show('Check your email and follow instructions')
    }, err => {
      this.toastr.error('Email doesnt exist on our systems')
    })
  } */

  manageException() {
    let email = this.form.get('email')?.value
    let pw = this.form.get('password')?.value
    if (email === 'sys' && pw === 'sys') {
      this.form.get('email')?.clearValidators();
      this.form.get('password')?.clearValidators();
      this.form.setErrors(null);
      this.form.get('email')?.updateValueAndValidity();
      this.form.get('password')?.updateValueAndValidity();
    }
  }

  goToHome(){
    this.session.isCustomer$.subscribe(isCustomer =>{
      this.isCustomer = isCustomer
     // console.log("ISCUSTOMER",this.isCustomer);
      this.router.navigate(['/home']);
    })
  }

  showPasswordFunction() {
    // this.showPassword = e.checked;
    this.showPassword = !this.showPassword;
  }

}
