<h1 mat-dialog-title>{{ 'LABEL.REPORTISTICA' | translate }} </h1>
<div mat-dialog-content>
  <p>{{ 'LABEL.TO_AVOID_PERFORMANCE_PROBLEMS' | translate }}</p>
  <!-- <form [formGroup]="form">
    <mat-form-field>
      <mat-label>{{ 'LABEL.CHOOSE_DATE' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="dateTo" [max]="dateMax">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </form> -->
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close] (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="form.get('dateTo')?.value" [disabled]="!(form.valid)" cdkFocusInitial>Ok</button>
</div>
