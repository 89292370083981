<div  class="search-wrapper mat-elevation-z2">
  <div class="search" fxLayout="row" fxLayoutAlign="start ">
    <div *ngIf="(isLoading === true)">
      <mat-spinner  style="float:right;" diameter="25">
      </mat-spinner>
    </div>

      <mat-icon>search</mat-icon>
      <input  matInput
              type="text"
              [placeholder]="translate.instant('FullTextSearch.Search')"
              [(ngModel)]="kwSearch"
              [value]="kwSearch"
              (ngModelChange)="fulltextSearch($event)"
              [formControl]="myControl"
              [readonly]="disableSearch"
              [matAutocomplete]="auto">

    <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">

      <div>
        <mat-option *ngFor="let option of optionList " [value]="option" [disabled]="edit">
          <span *ngIf="(service.name === 'CustomerService') && !(option.email == null)">
            <!-- {{option?.firstname}} -->
            <b>{{option.email}}</b>
          </span>
          <span *ngIf="(service.name == 'CardService' || service.name == 'FTIndexAllService')">{{option?.customer?.email }} <b>
            {{option.code}}</b></span>

          <span *ngIf="(service.name == 'MovementService')">
            {{option?.card.code }} 
            <b>{{option.reason.code}}</b>
          </span>
          <span *ngIf="(service.name == 'UserService')">
            {{option?.code }} 
            <b>{{option.email}}</b>
          </span>
          <span *ngIf="(service.name == 'PermanancyService')">
            {{option.checkInDate | date}} - 
            <b>{{option?.customer[0].firstname }} </b>
          </span>
          <span *ngIf="(service.name == 'MovementReasonService')">
            <!-- {{"prova"+option.code }} - 
            <b>{{option.code }} </b> -->
          </span>
         

          <span *ngIf="(service.name == 'RequestService')">
            <!-- {{ option?.id !== null && option?.id !== undefined? option?.id + ', ' : '' }} -->
            {{ option?.subject?.name !== null && option?.subject?.name !== undefined? option?.subject?.name + ' ' : '' }}
            {{ option?.subject?.surname !== null && option?.subject?.surname !== undefined? option?.subject?.surname + ' (' : ' (' }}
            <!-- {{ option?.assignmentOperator !== null && option?.assignmentOperator !== undefined? option?.assignmentOperator + ', (' : '' }} -->
            {{ option?.building?.streetName !== null && option?.building?.streetName !== undefined? option?.building?.streetName + ', ' : '' }}
            {{ option?.building?.streetNumber !== null && option?.building?.streetNumber !== undefined? option?.building?.streetNumber + ', ' : '' }}
            {{ option?.building?.city !== null && option?.building?.city !== undefined? option?.building?.city + ') ' : ')' }}
            <b>{{'REQUEST.REQUEST' | translate }}</b></span>

        </mat-option>
      </div>
      <div *ngIf="(noResults == true)">
        <!-- <mat-option disabled>
          <span *ngIf="searchType !== 'CardService'"><b>{{'FullTextSearch.NoSearchResults' | translate}}</b></span>
        </mat-option> -->
      </div>
    </mat-autocomplete>

      <button style="background-color: transparent;" matTooltip="Clear" type="button" class="btn" (click)="clear()">
        <mat-icon class="material-icons" style="color:#293683;">clear</mat-icon><!--  btn-primary mx-2 -->
      </button>

  </div>

</div>
