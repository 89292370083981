<div  class="search-wrapper mat-elevation-z2">
    <div class="search" fxLayout="row" fxLayoutAlign="start ">
        <input matInput
            (keyup)="applyFilter($event)"
            [placeholder]="translate.instant('FullTextSearch.Search')"
            [formControl]="myControl"
            [matAutocomplete]="auto"
            #input
            >

            <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="onSelectionChanged($event)">

                <div>
                  <mat-option *ngFor="let option of this.options  " [value]="option"><!-- this.dataSource.filteredData -->
    
                    <span *ngIf="searchType === 'MovementReasons'">
                      {{'MovementReason.Code.'+option?.code | translate }} 
                      <b *ngIf="option.points">{{' - '+option?.points + ' Coins'}}</b>
                    </span>

                    <span *ngIf="searchType === 'CardTypes'">
                      {{option?.description }} 
                      <b>{{option.code}}</b>
                    </span>

                    <span *ngIf="searchType === 'MovementType'">
                      {{option?.description }} 
                      <b>{{option.code}}</b>
                    </span>

                    <span *ngIf="searchType === 'PermanencyType'">
                      {{option?.description }} 
                      <b>{{option.code}}</b>
                    </span>

                    <span *ngIf="searchType === 'PermanencyStatus'">
                      {{option?.description }} 
                      <b>{{option.code}}</b>
                    </span>

                    <span *ngIf="searchType === 'Profile'">
                      {{option?.description }} 
                      <b>{{option.code}}</b>
                    </span>

                    <span *ngIf="searchType === 'Actions'">
                      {{option?.description }} 
                      <b>{{option.code}}</b>
                      ( URL: {{option?.path}} ) 
                    </span>

                    <span *ngIf="searchType === 'EmailTemplate'">
                      <div style="display: flex; align-items: center;">
                        <mat-icon style="">{{ option?.isActive === true? 'offline_pin': 'do_not_disturb_on'  }}</mat-icon> 
                        {{option.id }}
                      </div>
                    </span>
                  </mat-option>
                </div>
                <div *ngIf="(this.dataSource.filteredData.length === 0)">
                  <!-- <mat-option disabled>
                    <span *ngIf="searchType !== 'CardService'"><b>{{'FullTextSearch.NoSearchResults' | translate}}</b></span>
                  </mat-option> -->
                </div>
            </mat-autocomplete>
        <button style="background-color: transparent;" matTooltip="Clear" type="button" class="btn" (click)="clearSearch()">
            <mat-icon class="material-icons" style="color:#293683;">clear</mat-icon><!--  btn-primary mx-2 -->
        </button>
    </div>
</div>