import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-excel-export-dialog',
  templateUrl: './excel-export-dialog.component.html',
  styleUrl: './excel-export-dialog.component.scss'
})
export class ExcelExportDialogComponent {
  
  dateTo?: Date;
  dateMax: Date = new Date();

  form!: FormGroup;
  constructor(public dialogRef: MatDialogRef<ExcelExportDialogComponent>,
              private fb: FormBuilder,
              public translate: TranslateService) {
    this.form = this.fb.group({
      dateTo: [null, [Validators.required]]
    })
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.form.get('dateTo')?.valueChanges.subscribe(date => {
      console.log("DATE -> ", date)
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
