export const environment = {
  production: false,

  // baseApiUrl: 'http://localhost:80/',
   //baseApiUrl: 'https://apinetfidelity.dune.it/', 
  baseApiUrl: 'https://api.loyalty.campingpuntala.it/',
  rootApiLog: '',
  //  postLogoutRedirectUri: 'http://localhost:4200',
  postLogoutRedirectUri: 'https://loyalty.campingpuntala.it/',
  redirectUri: '',
}
