import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './session/login/login.component';
import { AdminGuard } from './guard/is-admin.guard';
import { DashboardGuard } from './components/dashboard/dashboard.guard';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { ChangeStatusComponent } from './components/change-status/change-status.component';

const routes: Routes = [
{ path: '', redirectTo: '/home', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'login-failed', component: LoginComponent },
  { path: 'change-password/:token', component: ChangePasswordComponent },
  { path: 'home', loadChildren: () => import('../app/components/dashboard/dashboard.module').then(mod => mod.DashboardModule), canActivate: [AuthGuard] },
  { path: 'users', loadChildren: () => import('../app/users/client-users.module').then(mod => mod.ClientUsersModule), canActivate: [AuthGuard,AdminGuard] },
  { path: 'profiles', loadChildren: () => import('../app/components/profiles/profiles.module').then(mod => mod.ProfilesModule), canActivate: [AdminGuard,AuthGuard] },
  { path: 'movement-reasons', loadChildren: () => import('../app/components/movement-reasons/movement-reasons.module').then(mod => mod.MovementReasonsModule), canActivate: [AdminGuard,AuthGuard] },
  { path: 'actions', loadChildren: () => import('../app/components/actions/actions.module').then(mod => mod.ActionsModule), canActivate: [AdminGuard,AuthGuard] },
  { path: 'card-type', loadChildren: () => import('../app/components/type/card-types/card-types.module').then(mod => mod.CardTypesModule), canActivate: [AdminGuard,AuthGuard] },
  { path: 'movement-type', loadChildren: () => import('../app/components/type/movement-type/movement-type.module').then(mod => mod.MovementTypeModule), canActivate: [AdminGuard,AuthGuard] },
  { path: 'permanancy-type', loadChildren: () => import('../app/components/type/permanancy-types/permanancy-types.module').then(mod => mod.PermanancyTypesModule), canActivate: [AdminGuard,AuthGuard] },
  { path: 'customers', loadChildren: () => import('../app/components/customers/customers.module').then(mod => mod.CustomersModule), canActivate: [AuthGuard] },
  { path: 'cards', loadChildren: () => import('../app/components/cards/cards.module').then(mod => mod.CardsModule), canActivate: [AuthGuard] },
  { path: 'movements', loadChildren: () => import('../app/components/movements/movements.module').then(mod => mod.MovementsModule), canActivate: [AuthGuard] },
  { path: 'permanencies', loadChildren: () => import('../app/components/permanencies/permanency/permanencies.module').then(mod => mod.PermanenciesModule), canActivate: [AuthGuard] },
  { path: 'permanency-status', loadChildren: () => import('../app/components/registry/permanancy-status-registry/permanancy-status-registry.module').then(mod => mod.PermanancyStatusRegistryModule), canActivate: [AdminGuard,AuthGuard] },
  { path: 'permanency-status-change', loadChildren: () => import('../app/components/registry/permanancy-status-change-registry/permanancy-status-change-registry.module').then(mod => mod.PermanancyStatusChangeRegistryModule), canActivate: [AdminGuard,AuthGuard] },
  { path: 'email-setting', loadChildren: () => import('./email/email.module').then(mod => mod.EmailModule), canActivate: [AdminGuard,AuthGuard] },
  { path: 'summary', loadChildren: () => import('./components/summary/summary.module').then(mod => mod.SummaryModule), canActivate : [AuthGuard]},
  { path: 'change-status', loadChildren: () => import('./components/change-status/change-status.module').then(mod => mod.ChangeStatusModule), canActivate : [AuthGuard]},
  { path: '**', redirectTo: '/home' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
