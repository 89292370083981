import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  public title: any = '';
  public subTitle: any = '';

  public actions: any = [
    'detail',
    'new',
    'upload',
    'list_export',
  ];
  public conf: any = {};

  constructor() {}

  getTitle() {
    return this.title;
  }

  setTitle(title: any) {
    this.title = title;
  }

  getConf() {
    return this.conf;
  }

  setConf(conf: any) {
    this.conf = conf;
  }

  getSubTitle() {
    return this.subTitle;
  }

  setSubTitle(subTitle: any) {
    this.subTitle = subTitle;
  }

  getActions() {
    return this.actions;
  }

  setActions(actions: any) {
    this.actions = actions;
    // console.log("azioni settate -> ", actions)
  }

  resetActions() {
    this.actions = [];
  }

  hasAction(name: string): boolean {
    //console.log('ACTIONS %o', this.navService.getActions());
    for (let a = 0; a < this.getActions().length; a++) {
      if (name == this.getActions()[a]['name']) return true;
    }
    return false;
  }
}
