<form [formGroup]="form" class="list">
    <h2>
      {{ 'User.ChangePassword2' | translate }}
    </h2>
      <div class="fxFlex-column" style="display:flex">
        <div class="anagrafica-1">

          <mat-form-field appearance="outline" style="margin-top: 3vh;">
            <mat-label>{{'User.Email.Label' | translate}}</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>

          <mat-form-field appearance="outline" >
            <mat-label>{{'User.Password.Label' | translate}}</mat-label>
            <input  [type]="showPassword === true? 'text': 'password'" matInput formControlName="password" required>
            <mat-icon matSuffix (click)="showPasswordFunction()" matTooltip="La password deve essere lunga almeno 8 caratteri,
            con almeno 1 lettera maiuscola, 1 numero e 1 carattere speciale.">
              {{showPassword === true? 'visibility_off': 'visibility'}}
            </mat-icon>
          </mat-form-field>
          <mat-error *ngIf="form.hasError('invalidPassword')" style="margin-bottom: 1vh;  ">
            <span style="display: flex; align-items: center;">
              Invalid password.
              <mat-icon matTooltip="Password must be at least 8 characters long, with at least 1 capital letter, 1 number and 1 special char."
                        >
                info
              </mat-icon>
            </span>
          </mat-error>

          <mat-form-field appearance="outline" style="margin-bottom: 3vh;">
            <mat-label>{{'User.PasswordConfirmation.Label' | translate}}</mat-label>
            <input type="password" matInput formControlName="passwordConfirmation" required>
          </mat-form-field>
          <mat-error *ngIf="form.hasError('passwordsDoNotMatch')">
            Confirmation does not match password
          </mat-error>

          <button mat-raised-button (click)="changePassword()" color="primary" [disabled]="!this.form.valid">
            {{ 'User.ChangePassword2' | translate }}
          </button>
          <button mat-stroked-button routerLink="/login" color="primary" *ngIf="hasChanged" style="margin-top: 10px;">
            {{ 'User.GoTo.Login' | translate }}
          </button>
        </div>
      </div>

</form>
