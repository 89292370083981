import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateOrDefault'
})
export class TranslateOrDefaultPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: string, fallback: string): string {
    const translation = this.translate.instant(value);
    return translation !== value ? translation : fallback;
  }
}
