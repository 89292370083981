import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { LibService } from './lib.service';
import { CreateQueryParams, QueryJoin, QueryJoinArr, RequestQueryBuilder } from '@nestjsx/crud-request';
import { Observable } from 'rxjs';
import { OutgoingEmailSettings } from '../models/email-template';
import { BaseService } from '../base/base.service';
import { HttpClient } from '@angular/common/http';

export interface FindOneQueryParams {
  join?: QueryJoin | QueryJoinArr | Array<QueryJoin | QueryJoinArr>;
  fields?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class OutgoingEmailService extends BaseService<OutgoingEmailSettings> {

  baseApiUrl = environment.baseApiUrl + 'email'

  constructor(public service: LibService,
    protected override http : HttpClient
  ) 
  { 
    super(service,http);
  }

  findAll(){
    return this.service.getData(this.baseApiUrl, '')
  }

  findOneTemplate(id: string, params: FindOneQueryParams = {}): Observable<OutgoingEmailSettings> {
    let queryParams: CreateQueryParams;
    let qb;
    if (params) {
      queryParams = {
        join: params.join,
        fields: params.fields
      }
      qb = RequestQueryBuilder.create(queryParams);
    }
    return this.service.getData(`${this.baseApiUrl}/${id}${qb ? '?' + qb.query() : ''}`, '');
  }

  update(id: string, data: any){
    return this.service.putData(data,`${this.baseApiUrl}/`, id)
  }
}
