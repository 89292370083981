import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter, Subscription } from 'rxjs';
import { OutgoingEmailSettings } from 'src/app/models/email-template';
import { OutgoingEmailService } from 'src/app/services/outgoing-email.service';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit {

  form: FormGroup;
  hoverRow: any;
  settings?: OutgoingEmailSettings;
  isNew?: boolean;

  summernoteConfig = {
    placeholder: '',
    tooltip: false,
    tabsize: 2,
    height: 300,
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear'
        ]
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['picture', 'link', 'hr']]
    ],
    fontNames: [
      'pfdintex',
      'Helvetica',
      'Arial',
      'Courier New',
      'Roboto',
      'Times'
    ]
  };

  currentLang: string = this.translate.currentLang;
  currentCountry: string = '*'
  currentStoreType: string = 'inStore'
  languages = this.translate.langs;
  storeTypes = [{
    code: 'inStore',
    desc: 'In Store'
  },
  {
    code: 'oca',
    desc: 'OCA'
  }]

  currentLanguageControl = new FormControl(/* this.translate.currentLang */);
  currentCountryControl = new FormControl('*');
  currentStoreTypeControl = new FormControl('inStore')

  get titleFormArray() {
    return this.form.get('title') as FormArray;
  }
  get textFormArray() {
    return this.form.get('text') as FormArray;
  }

  // private curLangControlSub: Subscription;
  private curCountryControlSub?: Subscription;
  private curStoreTypeControlSub?: Subscription;
  private navSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
    private settingsService: OutgoingEmailService,
  ) {
    this.form = this.fb.group({
      id: '',
      title: this.fb.array([], Validators.required),
      text: this.fb.array([], Validators.required),
      isActive: null,
      lang: ''
    });

    //this.currentCountryControl.setValue('')

    this.form.get('lang')?.valueChanges.subscribe(
      lang => {
        this.currentLang = lang!;
        this.checkTextForLang(lang!);
        this.checkTitleForLang(lang!);
      }
    );
    this.navSub = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(event => {
        this.currentLang = this.translate.currentLang;
        this.currentLanguageControl.setValue(this.currentLang);
        this.checkTextForLang(this.currentLang);
        this.checkTitleForLang(this.currentLang);
      });
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: any) => {
      console.log("params --> ", params);
      params.params.id === 'new'?
        this.isNew = true:
        this.isNew = false;
    })
    this.route.data.subscribe(data => this.initForm(data['settings']));
  }

  ngOnDestroy() {
    if (this.navSub) {
      this.navSub.unsubscribe();
    }

    // if (this.curLangControlSub) {
    //   this.curLangControlSub.unsubscribe();
    // }

    if (this.curCountryControlSub) {
      this.curCountryControlSub.unsubscribe();
    }

    if (this.curStoreTypeControlSub) {
      this.curStoreTypeControlSub.unsubscribe();
    }
  }

  initForm(settings: OutgoingEmailSettings = {}) {
    if (settings !== null && settings !== undefined) {
      this.settings = settings;
      this.form.patchValue(this.settings);
      console.log("ISACTIVE",this.form.get('isActive')?.value);
      console.log("settings --> ", this.settings);



      const titleGroups = this.settings.title!.map(n => this.fb.group(n));
      this.form.setControl('title', this.fb.array(titleGroups));

      const textGroups = this.settings.text!.map(n => this.fb.group(n));
      this.form.setControl('text', this.fb.array(textGroups));
    }
  }

  save() {
    if (this.form.valid) {
      if (this.isNew === true) {
        this.settingsService
        .createOne(this.cleanEmptyProperties(this.form.getRawValue()))
        .subscribe(
          (settings: any) => {
            this.toastr.success(
            this.translate.instant('Toastr.success_message'));
            this.initForm(settings);
          },
          (err: any) => this.handleError(err)
        )
      } else {
        this.settingsService
        .update(this.form.value.id, this.cleanEmptyProperties(this.form.value))
        .subscribe(
          (settings: any) => {

            this.toastr.success(
            this.translate.instant('Toastr.success_message'));
            this.initForm(settings);
          },
          (err: any) => this.handleError(err)
        );
      }
      
    }
  }

  handleError(error: any) {
    // this.notifications.openError(error);
    this.toastr.error(error)
  }

  checkTitleForLang(lang: string) {
    const i = this.titleFormArray.controls.findIndex(
      c => c.get('lang')?.value === (this.currentLang || 'en')
    );
    console.log("titleFormArray ->",this.titleFormArray);

    if (i < 0) {
      this.titleFormArray.push(
        this.fb.group({
          lang: lang,
          text: ''
        })
      );
    }
  }

  checkTextForLang(lang: string) {
    const i = this.textFormArray.controls.findIndex(
      c => c.get('lang')?.value === (this.currentLang || 'en')
    );
    if (i < 0) {
      this.textFormArray.push(
        this.fb.group({
          lang: lang,
          text: ''
        })
      );
    }
  }

  cleanEmptyProperties(object: any) {
    const obj = Object.assign({}, object);
    Object.keys(obj).forEach((key) => (!obj[key] || obj[key].length === 0) && delete obj[key]);
    return obj;
  }
}
