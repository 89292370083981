import { MatChipsModule, MAT_CHIP } from '@angular/material/chips';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DataTableComponent } from './data-table/data-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SelectSearchComponent } from './select-search/select-search.component';
import { AddressDetailFormComponent } from './address-detail-form/address-detail-form.component';
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { MatCardModule } from '@angular/material/card';
import { ExcelExportDialogComponent } from './excel-export-dialog/excel-export-dialog.component';
import { FullTextSearchModule } from './full-text-search/full-text-search.module';
import { ExcelUploaderComponentComponent } from './excel-uploader-component/excel-uploader-component.component';
import { FullTextSearchOfflineComponent } from './full-text-search-offline/full-text-search-offline.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TranslateOrDefaultPipe } from '../pipes/translateOrDefault.pipe';

const SHARED_MODULES = [
  MatCardModule,
  MatTableModule,
  MatPaginatorModule,
  MatButtonModule,
  MatSortModule,
  MatIconModule,
  MatInputModule,
  MatFormFieldModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  MatChipsModule,
  MatDialogModule,
  // MatLegacyChipsModule
  // MAT_CHIP,
  MatAutocompleteModule,
  MatSelectModule
]

@NgModule({
  declarations: [
    AddressDetailFormComponent,
    DataTableComponent,
    DetailPageComponent,
    MultiSelectDropdownComponent,
    SelectSearchComponent,
    SimpleDialogComponent,
    SafeHtmlPipe,
    ExcelExportDialogComponent,
    ExcelUploaderComponentComponent,
    FullTextSearchOfflineComponent,
    SpinnerComponent,
    TranslateOrDefaultPipe
    
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    DatePipe,
    FormsModule,
    TranslateModule.forChild(),
    HttpClientModule,
    NgxMatSelectSearchModule,
    SHARED_MODULES,
    FullTextSearchModule,
  ],
  exports: [
    AddressDetailFormComponent,
    DataTableComponent,
    DetailPageComponent,
    MultiSelectDropdownComponent,
    SelectSearchComponent,
    SafeHtmlPipe,
    TranslateOrDefaultPipe,
    FullTextSearchModule,
    FullTextSearchOfflineComponent,
    SpinnerComponent,
    
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule { }
