
<app-detail-page
    [title]="'Template.Title' | translate"
    [saveDisabled]="form.invalid" routeBack="/email-setting" (save)="save()">
  <form [formGroup]="form">
    <div style="padding-top: 12px">
      <div>

        <mat-form-field appearance="outline" class="flex-full">
          <mat-label> {{'Email.Template.CurrentLanguage.Label' | translate}} </mat-label>
          <mat-select [formControl]="currentLanguageControl" formControlName="lang">
            <mat-option *ngFor="let lang of languages" [value]="lang">{{'Email.Template.CurrentLanguage.'+lang | translate}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-checkbox formControlName="isActive" >
          {{'Email.Template.IsActive' | translate}}
        </mat-checkbox>

      </div>
      <div [formGroup]="form" class="fxFlex-column">
        <div formArrayName="title">
          <div class="fxFlex-column" *ngFor="let control of titleFormArray.controls; let i = index" [formGroupName]="i">

            <mat-form-field appearance="outline" class="flex-full" *ngIf="control.value.lang == currentLang">
              <mat-label>{{'Email.Detail.New.Title.'+currentLang | translate}}</mat-label>
              <input matInput formControlName="text">
            </mat-form-field>

          </div>
        </div>
        <div formArrayName="text">
          <div class="fxFlex-column" *ngFor="let control of textFormArray.controls; let i = index" [formGroupName]="i">
            <div [ngxSummernote]="summernoteConfig" formControlName="text" *ngIf="control.value.lang == currentLang"></div>
          </div>
        </div>
      </div>
    </div>
  </form>
</app-detail-page>
