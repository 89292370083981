import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ClientAuthModule } from '../auth/client-auth.module';

import { LoginComponent } from './login/login.component';
import { API_SERVICES_BASE_URL } from './constants';
import { SessionService } from './login/session.service';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { MatCheckboxModule } from '@angular/material/checkbox';

const MATERIAL_MODULES = [
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatToolbarModule,
  MatSelectModule,
  MatCardModule,
  MatTooltipModule,
  MatCheckboxModule
];

export interface ClientSessionModuleOptions {
  baseApiUrl: string;
}

@NgModule({
  imports: [
    CommonModule,
    ToastrModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    MATERIAL_MODULES,
    ClientAuthModule.forChild()
  ],
  declarations: [LoginComponent],
  providers: [SessionService]
})
export class ClientSessionModule {
  static forRoot(
    options: ClientSessionModuleOptions
  ): ModuleWithProviders<ClientSessionModule> {
    return {
      ngModule: ClientSessionModule,
      providers: [
        {
          provide: API_SERVICES_BASE_URL,
          useValue: options.baseApiUrl
        }
      ]
    };
  }
}
