import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatTableModule} from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';

import { HomeComponent } from './home/home.component';
import { ActionsModule } from './components/actions/actions.module';
import { FrameModule } from './components/frame/frame.module';
import { ProfilesModule } from './components/profiles/profiles.module';
import { RegistryModule } from './components/registry/registry.module';
import { TypeModule } from './components/type/type.module';
import { MovementReasonsModule } from './components/movement-reasons/movement-reasons.module';
import { environment } from './environments/environment';
import { ClientSessionModule } from './session/client-session.module';
import { SharedModule } from './shared/shared.module';
import { ClientAuthModule } from './auth/client-auth.module';
import { JwtInterceptorService } from './auth/jwt-interceptor.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { ClientUsersModule } from './users/client-users.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatIconSVGService } from './services/mat-icon-svg.service';
import { BlockUIModule } from 'ng-block-ui';
import { NgxEchartsModule } from 'ngx-echarts';
import { EmailModule } from './email/email.module';
import { SessionQuery } from './session/login/session-query';


export const MATERIAL_MODULES = [
  MatIconModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatTableModule,
  MatChipsModule
]

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    MATERIAL_MODULES,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FrameModule,
    SharedModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      countDuplicates: false,
      extendedTimeOut: 5000,
      positionClass: 'toast-top-right',
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BlockUIModule.forRoot(),
    ClientAuthModule.forRoot({
      authCookieName: 'app-auth'
    }),
    ClientSessionModule.forRoot({
      baseApiUrl: environment.baseApiUrl
    }),
    EmailModule,

    // NOTE: THE COMMENTED MODULES ARE LAZY LOADED, SO WE DON'T HAVE TO IMPORT THEM HERE
    // BECAUSE AT THE REFRESH OF THE PAGE THEY AREN'T LOADED

    //ClientUsersModule,
    // ActionsModule,
    // ProfilesModule,
    // RegistryModule,
    // TypeModule,
    // DashboardModule,
    // MovementReasonsModule,
    NgxEchartsModule.forRoot({
      echarts: ()=> import('echarts')
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
    MatIconSVGService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public translate: TranslateService, public sessionQuery : SessionQuery) {

    let isSet = false;
    let customer: any;

    translate.addLangs(['en', 'it', 'de']);

    this.sessionQuery.isCustomer$.subscribe(res => {
      if (!res) {
        translate.setDefaultLang('it');
        translate.use('it');
      } else {
        customer = JSON.parse(localStorage.getItem('Customer')!);
        translate.setDefaultLang(customer.language.toLowerCase());
        translate.use(customer.language.toLowerCase());
      }
      isSet = true;
    });
   
    translate.onLangChange.subscribe(() => {
      let t = translate.getDefaultLang();
      console.log("APP MODULE INIT", t);
    });
  }
}
