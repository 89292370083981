import { AfterViewInit, ChangeDetectorRef, Component, OnInit, SimpleChanges, inject, ViewChild, Input, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, pipe } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router, UrlTree } from '@angular/router';
import { NavbarService } from 'src/app/services/navbar.service';
import { ImportExportFileService } from 'src/app/services/import-export-file.service';
import { UserSessionService } from 'src/app/services/user-session.service';
import { AuthService } from 'src/app/auth/auth.service';
import { SessionService } from 'src/app/session/login/session.service';
import { UserService } from 'src/app/services/user.service';
import { Action, ActionsTree } from 'src/app/models/action';
import { User } from 'src/app/models/user';
import { ExcelUploaderComponentComponent } from 'src/app/shared/excel-uploader-component/excel-uploader-component.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MatSidenav } from '@angular/material/sidenav';
import { SessionQuery } from 'src/app/session/login/session-query';
import { HttpClient } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { CustomerService } from 'src/app/services/customer.service';


interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss'],
  host: {
    class: 'frame'
  }
})
export class FrameComponent {
  private breakpointObserver = inject(BreakpointObserver);
  @ViewChild('drawer') drawer!: MatSidenav;

  viewNavbar: boolean = false;
  actions: Action[] = [];
  loggedUser?: User;
  url?: string;
  previousUrl?: string;
  tempActions: Action[] = []
  isCustomer?: boolean;
  @Input() isInactive?: boolean;




  /* Mat tree */

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  private _transformer = (node: ActionsTree, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: this.translate.instant('Sidebar.' + node.code),
      icon: node.icon,
      code: node.code,
      path: node.path,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  dataSource: any;
  treeFlattener: any;
  isExpanded: boolean = false;

  key: any


  /* Mat tree */


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private route: ActivatedRoute,
    public navService: NavbarService,
    public fileService: ImportExportFileService,
    private userSessionService: UserSessionService,
    private userService: UserService,
    private authService: AuthService,
    private toastr: ToastrService,
    private sessionService: SessionService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    public translate: TranslateService,
    private session: SessionQuery,
    private customerService: CustomerService
  ) {
  }

  ngOnInit() { }

  ngAfterContentInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!
        const url = event.urlAfterRedirects;
        this.previousUrl = this.url;
        this.url = url;


        if (url.includes('login') || url.includes('change-password')) {
          this.viewNavbar = false
          this.cdr.detectChanges();
        } else {
          this.viewNavbar = true;
          this.getLoggedUser();

          this.cdr.detectChanges();
        }
        console.log('navbar visibile? ', this.viewNavbar);

      }
    })
  }

  ngOnChanges(sc: SimpleChanges) {
    console.log("SIMPLE CHANGES nav+sidebar", this.isInactive)
    this.isInactive === true ?
      this.logout() :
      console.log();
  }

  hasAction(name: string): boolean {
    //console.log('ACTIONS %o', this.navService.getActions());
    for (let a = 0; a < this.navService.getActions().length; a++) {
      if (name == this.navService.getActions()[a]['name']) return true;
    }
    return false;
  }

  getAction(name: string) {
    let action = this.navService
      .getActions()
      .filter((a: any) => a.name == name);
    return action.length == 1 ? action[0] : null;
  }

  downloadExportInPageIsActive(action: string) {
    let actionParams = this.getAction(action);
    return actionParams.items.length !== 0;
  }

  exportList(action: string) {
    let actionParams = this.getAction(action);
    console.log("actionParams --> ", actionParams);
    this.fileService.downloadExportDataList(actionParams)
  }

  exportTemplate(action: string) {
    if (this.downloadExportInPageIsActive(action)) {
      // se è presente almeno un dato avvia la generazione dell'export
      let actionParams = this.getAction(action); // recupera le informazioni collegate all'action "export_in_page_template"

      this.fileService.downloadTemplateExcel(actionParams)
    } else {
      // altrimenti restituisce un messaggio di errore
      this.toastr.warning(
        this.translate.instant('TOASTR.MESSAGE47'),
        this.translate.instant('LABEL.Info')
      );
    }
  }

  logout() {
    console.log("LOGOUT")
    this.viewNavbar = false
    this.drawer.close()
    this.actions = []
    // this.userSessionService.deleteUserSession(this.authService.getUser()?.email!)
    this.loggedUser !== undefined ?
      this.sessionService.logout(this.loggedUser) :
      console.log();
    this.router.navigate(['/login']);
    if (this.isInactive === true) {
      window.location.reload();
    }

    this.isInactive = false;
    // window.location.reload();
  }

  getLoggedUser() {
    if (this.viewNavbar === true) {
      let token = localStorage.getItem('User Token');
      let decoded = jwtDecode(token!) as any;
      let email = decoded.email;
      let user$ = this.userService.getByEmail(email).pipe(
        map((res: User) => {
          console.log("user --> ", res);
          this.loggedUser = res;
          return res;
        })
      );
      user$.subscribe(user => {
        user.profiles?.code === 'ADMIN' ? this.isCustomer = false : this.isCustomer = true;
        user.profiles!.actions?.forEach(item => {
          this.actions.find(a => a.code === item.code) !== undefined ?
            console.log() :
            this.actions.push(item);
        });

        // Move the second part here
        this.manageSidebar();
        // console.log("this.actions", this.actions);
        // console.log("actions --> ", this.actions.map(a => a.code));
        this.actions = [...new Set(this.actions)];
        this.makeTreeNavigation();
        this.isExpanded === true ?
          this.treeControl.expandAll() :
          this.treeControl.collapseAll();
        //this.removeDuplicates();
      });
    }
  }



  manageSidebar() {
    let key = localStorage.getItem('Customer')
    if (key) {
      const codedKey = JSON.parse(key)
      //        console.log("CHIAVE",key);
      if (codedKey.permanency === null) {
        this.actions = this.actions.filter((action: Action) => action.code !== 'PERMANENCIES');
        console.log("AZIONI SENZA PERMANENCY", this.actions);
      }
      let removeMovements = true;
      for (let i = 0; i < codedKey.cards.length; i++) {
        if (codedKey.cards[i].movements !== undefined && codedKey.cards[i].movements.length > 0) {
          removeMovements = false;
          break;
        }
      }
      if (removeMovements) {
        this.actions = this.actions.filter((action: Action) => action.code !== 'MOVEMENTS');
      }
    }
    return
  }

  /* removeDuplicates() {
    this.actions = this.actions.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.code === value.code && t.id === value.id
      ))
    )
  } */

  openExcelDialog() {
    //MASSIVE UPLOAD
    let service;
    let columns;
    console.log("url -> ", this.url);
    if (this.url!.includes('users')) {
      service = this.userService;
      columns = ['id', 'email', 'firstname', 'lastname', 'phoneNumber', 'password', 'userName']
      const dialogRef = this.dialog.open(
        ExcelUploaderComponentComponent,
        {
          width: '75%',
          height: '80%',
          data: {
            service: service,
            displayedColumns: columns
          }
        }
      );
      dialogRef.afterClosed().subscribe(result => { });
      this.cdr.detectChanges();
    }
  }

  goBack() {
    this.previousUrl !== null && this.previousUrl !== undefined ?
      this.router.navigate([this.previousUrl]) :
      this.router.navigate(['home'])
  }

  goToHome() {
    this.router.navigate(['home'])
  }

  ngOnDestroy() {
    this.logout()
  }

  makeTreeNavigation() {
    let mainActions: ActionsTree[] = this.actions.filter((a: any) => !(a.father?.length > 0));
    mainActions.map(a => a.children = []);
    this.actions.sort((a, b) => (a?.father > b.father) ? 1 : ((b.father > a.father) ? -1 : 0));
    let finalActions: ActionsTree[];
    mainActions.forEach((mainAction: ActionsTree) => {
      this.actions.forEach((childAction) => {
        if (childAction.father === mainAction.code) {
          mainAction.children!.push(childAction)
        }
      })
    })
    this.treeFlattener = new MatTreeFlattener(
      this._transformer,
      node => node.level,
      node => node.expandable,
      node => node.children,
    );
    // mainActions = mainActions.sort((a, b) => (this.translate.instant('Sidebar.' + a.code) > (this.translate.instant('Sidebar.' + b.code)) ? 1 : -1)) 
    mainActions.push(mainActions.splice(mainActions.findIndex(v => v.code == 'GESTIONE'), 1)[0])
    console.log("MAIN ACTIONS SORTED -> ", mainActions)
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener, mainActions);

  }

  expansionControl() {
    console.log("OPTIONS", this.treeControl.dataNodes)
    let dN: ExampleFlatNode = this.treeControl.dataNodes.find((a: any) => a.code === 'GESTIONE') as ExampleFlatNode;
    console.log("is Expanded? ", this.treeControl.isExpanded(dN))
    this.isExpanded = this.treeControl.isExpanded(dN)

  }

  changeLanguage(language: string) {
    this.translate.setDefaultLang(language);
    this.translate.use(language).subscribe(() => {
      let key = localStorage.getItem('Customer');
      if (key) {
        const customerData = JSON.parse(key);
        customerData.language = language;
        localStorage.setItem('Customer', JSON.stringify(customerData));
        this.loggedUser!.language = language
        this.customerService.updateOne(customerData.id, customerData)
      }
    })
  }

}
