import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/auth/auth.service";
import { Credentials, User } from "src/app/models/user";
import { API_SERVICES_BASE_URL } from "../constants";
import { Injectable, Inject} from '@angular/core';
import { tap } from "rxjs";

@Injectable()
export class SessionService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    @Inject(API_SERVICES_BASE_URL) private baseUrl: string
  ) { }

  login(credentials: Credentials) {
   // console.log(this.baseUrl)
    return this.http
      .post(this.baseUrl + 'auth/login', {
        email: credentials.email,
        password: credentials.password
      })
      .pipe(
        tap((res: any) => {
          //console.log("RES IN SESSION",res);

          const token = res.access_token;
          this.auth.setToken(token);
          const user = this.auth.getUser();
          //console.log("USER ->",user);
          const language = this.auth.getLang();
          localStorage.setItem("User Token",token)
          res.customer !== null? localStorage.setItem("Customer",JSON.stringify(res.customer)) : null
          //this.sessionStore.update({ token, user, language });
        })
      );
  }

  logout(loggedUser: User) {
    /*  const token = null;
    const user = null;
    const language = null; */

    localStorage.removeItem("User Token");
    localStorage.removeItem("Customer");

    sessionStorage.removeItem("User Token");
    sessionStorage.removeItem("Customer");

    this.auth.removeJwtToken();
    this.http.post(this.baseUrl + 'auth/logout', loggedUser).subscribe()

  }

  changePassword(user: User) {
    return this.http.post(this.baseUrl + 'auth/change-password', user);
  }

  validateChangePwToken(token: string) {
    return this.http.post(this.baseUrl + 'auth/validate-pw-token', { token: token });
  }
}
