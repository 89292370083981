import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private navService: NavbarService
  ) {
    console.log("INIT OME COMPONENT ---------------------------->")
  }

  ngOnInit(): void {
    this.initNavbar()
  }

  initNavbar() {
    console.log("INIT NAVBAR HOME-------------------------")
    let actions: any;

    actions = [
      { name: 'back', url: '' },
    ];

    let title = 'HomePage';

    // let subTitle = "ENPAL - " + this.translate.instant('LABEL.PARAMS')
    this.navService.setActions(actions);
    this.navService.setTitle(title);
  }

}
