import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../models/user';
import { jwtDecode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { AuthOptions } from './auth-options';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(
    @Inject(AuthOptions) private readonly authOptions: AuthOptions,
    private cookieService: CookieService,
    private translate: TranslateService
  ) { }

  setToken(token: string, language: string = 'it'): void {
    this.cookieService.deleteAll();
    const decodedToken = jwtDecode(token);
    const expirationDate = new Date(decodedToken.exp! * 1000);
    this.cookieService.set(this.authOptions.authCookieName!, language + token, expirationDate, undefined, undefined, false, 'Strict');
    this.translate.setDefaultLang(language)
    this.translate.use(language);
  }

  getJwtToken(): string {
    const cookieValue = this.cookieService.get(this.authOptions.authCookieName!);
    return cookieValue ? cookieValue.substr(2) : '';
  }

  getLang(): string {
    const cookieValue = this.cookieService.get(this.authOptions.authCookieName!);
    return cookieValue ? cookieValue.substr(0, 2) : '';
  }

  removeJwtToken(): void {
    this.cookieService.set(this.authOptions.authCookieName!, '', new Date(0), undefined, undefined, false, 'Strict');
    this.cookieService.deleteAll();
    localStorage.clear();
    sessionStorage.clear();
  }

  getUser(): User {
    const token = this.getJwtToken();
    const user = jwtDecode(token) as any;
    if (token) {
      try {
        this.translate.use(this.getLang());
        return user;
      } catch (error) {
        console.error('Error decoding JWT token:', error);
      }
    }
    return user ;
  }
}
