import { Injectable } from '@angular/core';
import {
  map,
  Observable,
  of,
  tap,
  Subject,
  throttleTime,
  shareReplay,
} from 'rxjs';
import { User } from '../models/user';
import { environment } from '../environments/environment';
import { LibService } from './lib.service';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  baseApi = environment.baseApiUrl + 'user';
  private $changeCompanySubject: Subject<boolean> = new Subject<boolean>();
  public get $changeCompanyDetector(): Observable<boolean> {
    return this.$changeCompanySubject
      .asObservable()
      .pipe(throttleTime(800), shareReplay());
  }

  constructor(public libService: LibService) {}

  /*
  for route, use window.location.href
  for obj, use and object like {searchNgModel: 'value'}  */
  saveState(route: string, obj: any) {
    sessionStorage.setItem(route, JSON.stringify(obj));
    this.$changeCompanySubject.next(true);
  }

  deleteState(route: string){
    sessionStorage.removeItem(route)
  }

  getUserAd(){
    return ;
  }

  deleteUserSession(email: string){
    this.deleteState(`user&${email}`)
  }

  getUser(str: string): Observable<User> {
    let userSessionStorage = this.getState(`user&${str}`);
    if (userSessionStorage === null) {
      return this.libService
        .getData<User>(this.baseApi, `/email/${str}`)
        .pipe(
          tap((user) => {
            this.saveState(`user&${str}`, user);
          })
        );
    } else {
      return of(userSessionStorage);
    }
  }


  getState(route: string): any | null {
    const state = sessionStorage.getItem(route);
    if (state) {
      return JSON.parse(state);
    } else {
      return null;
    }
  }

  getAccessToken(): any | null{
    const access_token = sessionStorage.getItem('access_token')
    if(access_token){
      return access_token
    }else{
      return null
    }
  }

  getIdToken(): any | null{
    const id_token = sessionStorage.getItem('id_token')
    if(id_token){
      return id_token
    }else{
      return null
    }
  }

  getRefreshToken(): any | null{
    const refresh_token = sessionStorage.getItem('refresh_token')
    if(refresh_token){
      return refresh_token
    }else{
      return null
    }
  }

  getExpiresAt(): any | null{
    const expires_at = sessionStorage.getItem('expires_at')
    if(expires_at){
      return parseInt(expires_at, 10)
    }else{
      return null
    }
  }

  getIdTokenExpiresAt(): any | null{
    const id_token_expires_at = sessionStorage.getItem('id_token_expires_at')
    if(id_token_expires_at){
      return parseInt(id_token_expires_at, 10)
    }else{
      return null
    }
  }

  getSignedUrl(objectUrl: string): Observable<string>{
    return this.libService.postData<any>({objectUrl: objectUrl}, this.baseApi, '/getSignedUrl').pipe(
      map(res => res.signedUrl)
    )
  }

  updateUser(user: User) {
    return this.libService.putData(user, this.baseApi, `/${user.id}`);
  }
}
