import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { UserService } from '../services/user.service';
import { User } from '../models/user';
import { AuthService } from '../auth/auth.service';
import { SessionQuery } from '../session/login/session-query';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  isUser?: boolean

  constructor(private session: SessionQuery, private router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.session.isCustomer$.subscribe(isCustomer => {
      this.isUser = isCustomer
    })
    if (!this.isUser) {
      return true;
    } else {
      return this.router.createUrlTree(['/home']);
    }
  }

}
