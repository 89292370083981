import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-full-text-search-offline',
  templateUrl: './full-text-search-offline.component.html',
  styleUrl: './full-text-search-offline.component.scss'
})
export class FullTextSearchOfflineComponent {

  @Input() dataSource!: MatTableDataSource<any>;
  @Input() searchType?: string;
  @Input() disableSearch?: boolean;

  @Output() selected = new EventEmitter;
  @Output() list = new EventEmitter;

  input = new Subject<string>();
  inputValue$ = this.input.pipe(debounceTime(200), distinctUntilChanged());
  selectedOpt: any;
  myControl: FormControl = new FormControl('');
  options: any[] = [];

  constructor(
    public translate: TranslateService,
    private router: Router
  ) {

  }

  ngAfterContentChecked() {
    this.inputValue$.pipe(switchMap((input: any)=> {
      const filterValue = (input.target as HTMLInputElement).value;
      let output = new MatTableDataSource(this.dataSource.data);
      output.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }  
      this.list.emit(output.filteredData)
      this.options = output.filteredData;
      return '';
    })).subscribe()
  }

  applyFilter(event: any) {
    this.input.next(event);
  }

  clearSearch() {
    this.input.next('');
  }
  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    console.log("TIPO DI SELEZIONE --> ", typeof event.option.value)
    if (typeof event.option.value == 'undefined') {
      console.log("DO NOTHING --> ", )
    } else {
      this.selectedOpt = event.option.value;

      console.log("SOGGETTO SELEZIONATO --> ", this.selectedOpt);

      switch (this.searchType) {
        case 'MovementReasons':
          this.myControl.setValue(this.translate.instant("MovementReason.Code.",this.selectedOpt.code));
          // this.selected.emit({value: this.selectedOpt, field: 'id'});
          this.router.navigate(['/movement-reasons', this.selectedOpt.id])
          break;

        case 'CardTypes':
          this.myControl.setValue(this.selectedOpt.code);
          // this.selected.emit({value: this.selectedOpt, field: 'id'});
          this.router.navigate(['/card-type', this.selectedOpt.code])
          break;
        case 'MovementType':
          this.myControl.setValue(this.selectedOpt.code);
          // this.selected.emit({value: this.selectedOpt, field: 'code'});
          this.router.navigate(['/movement-type', this.selectedOpt.code])
          break;
        case 'PermanencyType':
          this.myControl.setValue(this.selectedOpt.code);
          // this.selected.emit({value: this.selectedOpt, field: 'code'});
          this.router.navigate(['/permanancy-type', this.selectedOpt.id])
          break;
        case 'Profile':
          this.myControl.setValue(this.selectedOpt.code);
          // this.selected.emit({value: this.selectedOpt, field: 'code'});
          this.router.navigate(['/profiles', this.selectedOpt.id])
          break;
        case 'PermanencyStatus':
          this.myControl.setValue(this.selectedOpt.code);
          // this.selected.emit({value: this.selectedOpt, field: 'code'});
          this.router.navigate(['/permanency-status', this.selectedOpt.code])
          break;
          case 'Actions':
            this.myControl.setValue(this.selectedOpt.code);
            // this.selected.emit({value: this.selectedOpt, field: 'code'});
            this.router.navigate(['/actions', this.selectedOpt.id])
            break;
          case 'EmailTemplate':
            this.myControl.setValue(this.selectedOpt.id);
            // this.selected.emit({value: this.selectedOpt, field: 'code'});
            this.router.navigate(['/email-setting', this.selectedOpt.id])
            break;
        
      }

    }
  }
}
