import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseService } from '../base/base.service';
import { environment } from '../environments/environment';
import { Customer } from '../models/customer';
import { LibService } from './lib.service';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseService<Customer>{

  baseApiUrl: string = environment.baseApiUrl + "customer"

  name = 'CustomerService';

  constructor(public service : LibService,
    public router : Router,
    protected override http : HttpClient){
    super(service,http);
  }

  createCustomerAndUser(req: Customer): Observable<Customer | null> {
    return this.libService.postData<Customer>(req, environment.baseApiUrl, 'auth/create-customer-user').pipe(
      catchError(error => {
        console.error("Errore durante la creazione del cliente: ", error);
        // Puoi restituire null per gestire l'errore nel tuo frontend
        return of(null);  // Restituisci un Observable che emette `null` in caso di errore
      })
    );
  }
  

  goTo(e: any) {
    this.router.navigate(['customers/' + e.value[e.field]]); 
  }

}
