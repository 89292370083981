
<div class="background-white">

  <img  src="../../../assets/images/attachment.png">
  <form [formGroup]="form">
    <div  class="fxFlex-rows "
          >
      <span class="flex-25"></span>
      <div class="fxFlex-column flex-full">
        <!-- <h1>{{ 'Login.Title' | translate }}</h1> -->
        <h3 class="title-login">{{ 'Accedi a PROGRAMMA FEDELTÀ | PUNTALA +' | translate }}</h3>
        <mat-form-field appearance="outline" class="flex-full">
          <mat-label>{{ 'Email'  }}</mat-label>
          <!-- <mat-error *ngIf="form.get('email')?.hasError">
            Inserisci un indirizzo email valido.
          </mat-error> -->
          <input matInput formControlName="email" type="email">
        </mat-form-field>
        

        
          <mat-form-field appearance="outline" class="flex-full" >
            <mat-label matTooltip="La password deve essere lunga almeno 8 caratteri,
            con almeno 1 lettera maiuscola, 1 numero e 1 carattere speciale.">{{ 'Password' | translate }}</mat-label>
            <input matInput matTooltip="La password deve essere lunga almeno 8 caratteri,
            con almeno 1 lettera maiuscola, 1 numero e 1 carattere speciale." [type]="showPassword === true? 'text': 'password'" formControlName="password">
            
            <mat-error *ngIf="form.get('password')?.hasError && form.get('email')?.hasError">
            email o password non valida
            </mat-error>
            <!-- <mat-icon matSuffix matTooltip="La password deve essere lunga almeno 8 caratteri,
            con almeno 1 lettera maiuscola, 1 numero e 1 carattere speciale.">info</mat-icon> -->
            <mat-icon matSuffix (click)="showPasswordFunction()" matTooltip="La password deve essere lunga almeno 8 caratteri,
            con almeno 1 lettera maiuscola, 1 numero e 1 carattere speciale.">
              {{showPassword === true? 'visibility_off': 'visibility'}}
            </mat-icon>
          </mat-form-field>         

        <div>
          <button mat-flat-button color="primary" (click)="login()" [disabled]="!form.valid ">Login</button>
          <button mat-flat-button [disabled]="!form.get('email')?.valid" (click)="changePassword()" >Reset Password</button>
        </div>
      </div>
    </div>
  </form>
</div>
