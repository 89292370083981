<mat-toolbar  class="color-navbar mat-primary mat-toolbar"
              *ngIf="viewNavbar"
              >
  <!-- begin -->
  <div style="display: flex; justify-content: left;">
    <button
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="drawer.toggle()">
      <mat-icon aria-label="Side nav toggle icon">
        menu
      </mat-icon>
    </button>
    <button  [routerLink]="isCustomer ? '/summary' : '/change-status'"
    type="button"
    aria-label="Torna alla Home"
    mat-icon-button
    >
    <mat-icon>
      home
    </mat-icon>
    </button>

    <!-- <button
              mat-icon-button
              *ngIf="hasAction('back')"
              [matTooltip]="translate.instant('Navbar.Back')"
              (click)="goBack()"
              style="float:left">
      <mat-icon
              >
        arrow_back
      </mat-icon>
    </button> -->
  </div>

  <!-- TITOLO -->
  <div class="nav-image-container">
    <img class="nav-image-title" src="../../../assets/images/punta-ala-logo.png">
  </div>

  <div class="nav-title">
    <span>Loyalty - Program PuntAla </span>
    <span class="h4 enpal-title">
      {{navService.getTitle()}}
    </span><br/>
  </div>

  <!-- end -->

  <div style="display: flex; justify-content: right;">
    <button   mat-icon-button
              [matTooltip]="translate.instant('Navbar.DL_Data')"
              *ngIf="hasAction('list_export')"
              (click)="exportList('list_export')">
      <mat-icon >
        download
      </mat-icon>
    </button>
    <button   mat-icon-button
              [matTooltip]="translate.instant('Navbar.DL_Template')"
              *ngIf="hasAction('export_template')"
              (click)="exportTemplate('export_template')">
      <mat-icon
                svgIcon="file_save"></mat-icon>
    </button>
    <button   mat-icon-button
              *ngIf="hasAction('upload')"
              [matTooltip]="translate.instant('Navbar.UL_Data')"
              (click)="openExcelDialog()">
      <mat-icon
              >
        upload_file
      </mat-icon>
    </button>
    <!-- <span class="toolbar-item-spacer"></span> -->
    <div style="display: flex;align-items: center;justify-content: center;">
    <button mat-button 
      [matMenuTriggerFor]="menu" *ngIf="isCustomer"><mat-icon>language</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="changeLanguage('it')">Italiano</button>
        <button mat-menu-item (click)="changeLanguage('en')">English</button>
        <button mat-menu-item (click)="changeLanguage('de')">Deutsch</button>
      </mat-menu>

    <button   mat-icon-button
              [matTooltip]="translate.instant('Navbar.Logout')"
              (click)="logout()">
      <mat-icon
              >
        logout
      </mat-icon>
    </button>
  </div>
  </div>
</mat-toolbar>



<mat-sidenav-container class="sidenav-container" [ngClass]="viewNavbar? 'background-auth' : 'body' ">
  <mat-sidenav  class="color-sidenav" #drawer  [ngClass]="viewNavbar? 'visible ': 'hide'"
                        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                        [mode]="(isHandset$ | async) ? 'over' : 'side'" [autoFocus]="false"
                        [opened]="(isHandset$ | async) === false && viewNavbar === true? false: false">



                <!--
                  AVAILABLE MODES FOR MAT-SIDENAV: 'over', 'side'

                 -->

                 <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="mat-tree">
                  <!-- This is the tree node template for leaf nodes -->
                  <mat-nav-list [ngClass]="viewNavbar? 'visible ': 'hide'">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                    <!-- use a disabled button to provide padding for tree leaf -->
                    
                      <a mat-list-item [routerLink]="node.path" (click)="expansionControl()" style="margin-bottom: -15px; " routerLinkActive="active-list-item">
                        <div style="  display: flex;
                                      flex-direction: row;
                                      justify-content: left;
                                      align-items: center;">
                          <mat-icon [svgIcon]="node.code !== 'PERMANANCY-TYPE'?'' : node.icon">
                            {{ node.code !== 'PERMANANCY-TYPE'? node.icon: '' }}
                          </mat-icon>
                          <span style="margin-left: .8em;">
                          {{!isCustomer ? ('Sidebar.' + node.code | translate) : ('Sidebar.Customer.' + node.code | translate) }}
                          </span>
                        </div>
                      </a>
                    </mat-tree-node>
                  </mat-nav-list>
                  <!-- This is the tree node template for expandable nodes -->
                  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                    <!-- <button mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'Toggle ' + node.name">
                      
                    </button>
                    {{node.name}} -->

                    <a mat-list-item matTreeNodeToggle routerLinkActive="active-list-item">
                      <div style="  display: flex;
                                    flex-direction: row;
                                    justify-content: left;
                                    
                                    align-items: center;">
                        <mat-icon class="mat-icon-rtl-mirror">
                          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                        <span style="margin-left: .8em;">
                        {{!isCustomer ? ('Sidebar.' + node.code | translate) : ('Sidebar.Customer.' + node.code | translate) }}
                        </span>
                      </div>
                    </a>
                  </mat-tree-node>
                </mat-tree>

      <!-- <mat-nav-list  *ngFor="let action of actions" [ngClass]="viewNavbar? 'visible ': 'hide'">
        <a mat-list-item [routerLink]="action.path"  style="margin-bottom: -15px;" routerLinkActive="active-list-item">
          <div style="  display: flex;
                        flex-direction: row;
                        justify-content: left;

                        align-items: center;">
            <mat-icon [svgIcon]="action.code !== 'PERMANANCY-TYPE'?'' : action.icon">
              {{ action.code !== 'PERMANANCY-TYPE'? action.icon: '' }}
            </mat-icon>
            <span style="margin-left: .8em;">
            {{!isCustomer ? ('Sidebar.' + action.code | translate) : ('Sidebar.Customer.' + action.code | translate) }}
            </span>
          </div>
        </a>
      </mat-nav-list>-->
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">

     <!-- Add Content Here -->
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container> 
