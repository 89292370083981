import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core';
import { LibService } from './lib.service';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ExcelExportDialogComponent } from '../shared/excel-export-dialog/excel-export-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ImportExportFileService {

    constructor(
        private translate: TranslateService,
        private libService: LibService,
        private datePipe: DatePipe,
        private toastr: ToastrService,
        private dialog: MatDialog
    ) {

    }

    downloadExportDataList(obj: { name: string, type: string, dataSource: any[], fileName: string, sheetName: string }) {
        if (obj.name === 'list_export') {
          // se è presente almeno un dato avvia la generazione dell'export
          // recupera le informazioni collegate all'action "export_in_page"
    
          this.libService.lockPage(this.translate.instant('Navbar.preparing_file'));
          console.log("Export Dati -> ", obj.dataSource)

          let data = obj.dataSource;
          if (data?.length > 0) {
            let sc: any = []                      
            let workbook = XLSX.utils.book_new();
            let sheet = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(workbook, sheet, obj.sheetName);

            this.libService.unlockPage();
            var bin = XLSX.writeFile(workbook, obj.fileName);
          } else {
            this.libService.unlockPage();
            this.toastr.warning(
              this.translate.instant('Toastr.empty_list'),
              this.translate.instant('Toastr.warning')
            );
          }
        } else {
          // altrimenti restituisce un messaggio di errore
          this.toastr.warning(
            this.translate.instant('TOASTR.MESSAGE47'),
            this.translate.instant('LABEL.Info')
          );
        }
    }

    downloadTemplateExcel(obj: { name: string, type: string, url: string, items: any[], header: string, sheetName: string, fileName: string}) {
      
      let sheet = XLSX.utils.json_to_sheet(obj.items);
      // XLSX.utils.sheet_add_aoa(sheet, [actionParams.header], { origin: 'A1' });
      let workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, sheet, obj.sheetName);
      var bin = XLSX.writeFile(workbook, obj.fileName);
    }

    uploadDataFromExcel(obj: { name: string, url: string},) {
      
    }
    
}