import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';
import { Observable } from 'rxjs';
import { BaseService } from '../base/base.service';
import { environment } from '../environments/environment';
import { User } from '../models/user';
import { LibService } from './lib.service';
import { AuthService } from '../auth/auth.service';
import { FindManyOptions } from 'typeorm';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User>{

  name = 'UserService';
  baseApiUrl: string = environment.baseApiUrl + "users"

  token = this.auth.getJwtToken()

  constructor(public service : LibService,
    protected override http: HttpClient,
    public router : Router,
    private auth: AuthService){
    super(service, http);
  }

  changeEmail(id: number, user: User): Observable<User> {
    return this.http.put<User>(`${this.baseApiUrl}/change-email/${id}`, { user });
  }

  findUser(id : any, params : any = {}): Observable<User> {
    let queryParams: CreateQueryParams;
    let qb;
    if (params) {
      queryParams = {
        join: params.join,
        fields: params.fields
      }
      qb = RequestQueryBuilder.create(queryParams);
    }
    return this.http.get<User>(`${this.baseApiUrl}/${id}${qb ? '?' + qb.query() : ''}`);
  }

  getByEmail(email: string) {
    
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.token}`,
      'Content-Type': 'application/json'
    });
    return this.libService.getData<User>(this.baseApiUrl, '/' + email);
  }

  findUserByEmail(email : FindManyOptions): Observable<User>{
    return this.libService.postData<User>(email, environment.baseApiUrl, 'auth/findByEmail')
  }

  changePassword(data: {data: { email: string, password: string, oldPassword: string}, token: string}) {
    return this.http.post<User>(`${environment.baseApiUrl}auth/${data.data.email}/changePassword`, data);
  }

  sendResetPasswordRequest(data: { userEmail: string}){
    return this.http.post<User>(`${this.baseApiUrl}/sendResetPasswordRequest`, data);
  }

  goTo(e: any) {
    this.router.navigate(['users/'+ e.id]);
  }

}
