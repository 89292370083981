import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailTemplateListComponent } from './email-template-list/email-template-list.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { OutgoingEmailResolverService } from './outgoing-email-resolver.service';


const routes: Routes = [
  { path: '', component: EmailTemplateListComponent },
  { path: ':id', component: EmailTemplateComponent, resolve: { settings: OutgoingEmailResolverService } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmailRoutingModule { }
