import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthOptions } from './auth-options';

@Injectable()
export class PublicApiInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(AuthOptions) private readonly options: AuthOptions
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.options.backendUrl) {
      const re = new RegExp(`^${this.options.backendUrl}/api`,'g');
      if (re.test(req.url)) {
        req = req.clone({
          setHeaders: {
            'x-book-an-appointment-client': 'X'
          }
        });
      }
    }
    return next.handle(req);
  }
}
