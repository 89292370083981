import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class MatIconSVGService {
    constructor (
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        this.loadSVGIcons()
    }


    loadSVGIcons() {
        this.matIconRegistry.addSvgIcon('file_save', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/file_save.svg'))
        this.matIconRegistry.addSvgIcon('location_home', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/location_home.svg'))
    }
    

}