import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OverlayService } from 'src/app/services/overlay.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'src/app/session/login/session.service';
import { LibService } from 'src/app/services/lib.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [ UserService ]
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  loading = false;
  token: string ='';

  hasChanged = false
  showPassword = false;


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr : ToastrService,
    private translate: TranslateService,
    private usersService: UserService,
    private overlay: OverlayService,
    private sessionService: SessionService,
    private route: ActivatedRoute,
    private libService: LibService
  ) {

    this.route.paramMap.subscribe((res: any) => {
     // console.log("RESULT PARAMS -> ", res)
      this.token = res.params.token;
    })
    this.form = this.fb.group({
        email: ['', Validators.required],
        password: ['', Validators.required],
        passwordConfirmation: ['', Validators.required]
      },
      {
        validator: this.passwordValidator
      }
    );
  }

  ngOnInit(): void {
    this.validateToken();
  }

  initForm(user: User) {
    this.form.patchValue(user);
  }

  passwordValidator(form: FormGroup) {
    const passControl = form.get('password');
    const confirmPassControl = form.get('passwordConfirmation');
    let err = null;
    if (passControl && confirmPassControl) {
      const pass = passControl.value;
      const confirmPass = confirmPassControl.value;

      if (
        pass &&
        (pass.length < 8 ||
          !/[!@#$%^&*(),.?'":{}|<>;:°#§]/.test(pass) ||
          !/[A-Z]|[a-z]/.test(pass) ||
          !/[0-9]/.test(pass))
      ) {
        err = err
          ? Object.assign(err, {
              invalidPassword: true
            })
          : {
              invalidPassword: true
            };
      }

      if (pass !== confirmPass) {
        err = err
          ? Object.assign(err, {
              passwordsDoNotMatch: true
            })
          : {
              passwordsDoNotMatch: true
            };
      }
    }
    return err;
  }

  save() {
    if (this.form.valid) {
      this.loading = true;
      this.overlay.show()

    }
  }

  handleError(error : any) {
    this.loading = false;
    this.overlay.hide();
    this.toastr.error(error);
  }

  validateToken() {
    // this.libService.lockPage('')
    this.sessionService.validateChangePwToken(this.token).subscribe({
      next: (res: any) => {
       // console.log("risultato validazione -> ", res);
        // this.libService.unlockPage();
      },
      error: (err) => {
        //  console.log("errore -> ", err);
          this.toastr.error(
            this.translate.instant('Toastr.Invalid_or_expired_link'),
            this.translate.instant('Toastr.error')
          )
      },

    })
  }

  changePassword() {
    console.log("form get raw value --> ", this.form.getRawValue());
    let payload = {
      data: this.form.getRawValue(),
      token: this.token
    }
    this.usersService
      .changePassword(
        payload
      )
      .subscribe({
        next: (user) => {
          this.overlay.hide();
          this.loading = false;
          this.toastr.success(
            this.translate.instant('User.Messages.PasswordChanged'),
            this.translate.instant('Toastr.success')
          );
          this.hasChanged = true
          // this.router.navigate(['/']);
        },
        error: (err) => {
          // this.handleError(err)
          console.log("error -> ", err)
          this.toastr.error(
            this.translate.instant('Toastr.Invalid_or_expired_link'),
            this.translate.instant('Toastr.error')
          );

        }
      });
  }
  showPasswordFunction() {
    this.showPassword = !this.showPassword;
  }

}
