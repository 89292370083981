
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SessionQuery {
  authenticatedAsUser$ = of(localStorage.getItem('User Token') !== null);
  authenticatedAsCustomer$ = of(localStorage.getItem('Customer') !== null);
  isUser$ : Observable<boolean>
  isCustomer$ : Observable<boolean>

  constructor(){
    this.isUser$ = this.authenticatedAsUser$!.pipe(
        map(authenticated => authenticated && localStorage.getItem('User Token') !== null),
      );
    this.isCustomer$ = this.authenticatedAsCustomer$!.pipe(
        map(() => localStorage.getItem('Customer') !== null),
      );
  }
}
