import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { OutgoingEmailSettings } from 'src/app/models/email-template';
import { NavbarService } from 'src/app/services/navbar.service';
import { OutgoingEmailService } from 'src/app/services/outgoing-email.service';
import { DatatableAction, DatatableColumn } from 'src/app/shared/data-table/data-table.component';

@Component({
  selector: 'app-email-template-list',
  templateUrl: './email-template-list.component.html',
  styleUrls: ['./email-template-list.component.scss']
})
export class EmailTemplateListComponent implements OnInit {

  displayedColumns = ['id'];
  dataSource: any;

  columns: DatatableColumn<OutgoingEmailSettings>[] = [
    {
      name: 'id',
      flex: '1',
      title: this.translate.get('Email.Id.Label'),
      cssClass: setting => ['column-padding'],
      value:setting => setting.id
     // value: setting => this.translate.instant('EMAIL_LABEL.ID.'+setting.id)
    },
  ]

  actions: DatatableAction<OutgoingEmailSettings>[] = [
    {
      label: of(''),
      icon: (setting: any) => 'edit',
      onClick: setting => this.goTo(setting),
      color: 'primary'
    }
  ]

  constructor(
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private outgoingEmailService: OutgoingEmailService,
    private navService: NavbarService,

  ) { }

  ngOnInit(): void {
    this.outgoingEmailService.findAll().subscribe(res => {
      this.dataSource = res
      console.log("res",res);
      this.initNavbar();
    })
  }

  goTo(e: any){
    this.router.navigate([ e.id], {relativeTo: this.activatedRoute})
  }

  initNavbar() {
    let actions: any;
    // let template : User[];
    // template = [{id: '', email: '', firstname: '', lastname: '', phoneNumber: '', password: '', userName: ''}]

    actions = [
      { name: 'back', url: '' },
      // { name: 'upload', url:'params'},
      // { name: 'list_export', type: '', dataSource: this.dataSource, sheetName: 'Foglio 1', fileName: 'ExportProfiles.xlsx'},
      // { name: 'export_template', type: 'template', url:'params', items: template, header: 'params', sheetName: 'Foglio 1', fileName: 'TemplateParamsExport.xlsx'}
    ];

    let title = this.translate.instant('Email.Template.Title');
    // let subTitle = "ENPAL - " + this.translate.instant('LABEL.PARAMS')
    this.navService.setActions(actions);
    this.navService.setTitle(title);
    // this.navService.setSubTitle(subTitle);

  }
}
